<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            MikroBilling
          </h2>
        </b-link>

        <b-card-title class="mb-1 text-center">
          Welcome to MikroBilling! 👋
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
              <!-- mobile -->
              <b-form-group
                  label="Mobile Number"
                  label-for="login-mobile" >
                  <validation-provider
                      #default="{ errors }"
                      name="Mobile"
                      vid="mobile"
                      rules="required" >
<!--                      <b-form-input
                          id="login-mobile"
                          v-model="userMobile"
                          :state="errors.length > 0 ? false:null"
                          name="login-email"
                          placeholder="Type Mobile Number"
                      />-->
                      <VuePhoneNumberInput id="mobile" v-model="mobile" default-country-code="BD"  clearable @update="onUpdate" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
            <b-card-text class="text-center mt-2">
                <span>New on our platform? </span>
                <b-link :to="{name:'auth-isp-registration'}">
                    <span>&nbsp;Create an account</span>
                </b-link>
            </b-card-text>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from "@/store";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {checkRes} from "@core/services/helper";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput
  },
  mixins: [togglePasswordVisibility],
    data() {
        return {
            countryCallingCode : null,
            countryCode : null,
            phoneNumber : null,
            isValidMobile : null,
            mobile:null,
            status: '',
            password: '',
            userMobile: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),

            // validation rules
            required,
            email,

        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        onUpdate (payload) {
            this.countryCallingCode = payload.countryCallingCode
            this.countryCode = payload.countryCode
            this.phoneNumber = payload.nationalNumber
            this.isValidMobile = payload.isValid
        },
        login() {
            this.$refs.loginForm.validate().then(success => {
                if (success && this.isValidMobile) {
                    useJwt.login({
                        mobile: this.countryCallingCode + this.phoneNumber ?? this.userMobile,
                        countryCode: this.countryCode ?? null,
                        password: this.password,
                    }).then(response => {
                        if (checkRes(response.status) && response.data.data) {
                            const userData = response.data.data.user
                            useJwt.setToken(response.data.data.accessToken)
                            useJwt.setRefreshToken(response.data.data.accessToken)
                            localStorage.setItem('userData', JSON.stringify(userData))
                            this.$ability.update(userData.ability)

                            // ? This is just for demo purpose as well.
                            // ? Because we are showing eCommerce app's cart items count in navbar
                           // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                            // this.$router.replace(getHomeRouteForLoggedInUser('admin'))
                            this.$router.push('/')
                                .then(() => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Welcome ${userData.name}`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: 'You have successfully logged in as Admin. Now you can start to explore!',
                                        },
                                    })
                                }).catch(error => {
                                console.log(error)
                                this.$refs.loginForm.setErrors(error.response.data.error)
                            })
                        }else {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `ISP Register`,
                                    icon: 'CoffeeIcon',
                                    variant: 'warning',
                                    text: response.data.messages[0],
                                },
                            })
                        }
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
